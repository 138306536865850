<template>
  <div>
    <loading v-if="!email"></loading>
    <div class="container" v-else>
      <div class="row mb-3">
        <div class="col my-auto">
          <p class="mb-0 fw-bold">From: {{ email.sender }}</p>
        </div>
        <div class="col-auto my-auto">
          <button
            class="btn btn-sm btn-success me-3"
            v-if="email.client"
            @click="$router.push('/clients/' + email.client.id)"
          >
            <i class="far fa-user me-2"></i>View Profile
          </button>
          <button
            class="btn btn-sm btn-success me-3"
            @click="showReplyBox = true"
          >
            <i class="far fa-reply me-2"></i>Reply
          </button>

          <button class="btn btn-sm btn-outline-success" @click="markDealtWith">
            <i class="far fa-check me-2"></i>Mark Dealt With
          </button>
        </div>
        <div class="col-auto my-auto ms-auto">
          <p class="mb-0">Received {{ email.created_at | formatDate }}</p>
        </div>

        <div class="col-auto">
          <button
            class="btn btn-sm btn-outline-success"
            @click="$router.back()"
          >
            <i class="far fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card border-0 shadow-none">
        <div class="card-body">
          <h5 class="fw-bold">{{ email.subject }}</h5>

          <div v-if="showReplyBox">
            <div class="row mb-3">
              <div class="col">
                <vue-editor
                  ref="editor"
                  class="email-template-editor"
                  v-model="form.message"
                  required
                ></vue-editor>
              </div>
            </div>
            <div class="row">
              <div class="col-auto ms-auto">
                <button class="btn btn-success" @click="sendEmail">
                  <i class="far fa-check me-2"></i>Send Email
                </button>
              </div>
            </div>
          </div>

          <div v-if="!showReplyBox" v-html="email.body_html"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      email: null,
      showReplyBox: false,
      sending: false,
    };
  },
  computed: {
    form() {
      return {
        client_id: this.email.client ? this.email.client.id : null,
        message: this.email.body_stripped
          ? "<br><br><p>--</p><br>" + this.email.body_stripped
          : "",
        subject: this.email.subject ? "Re: " + this.email.subject : "",
        email_id: this.email.id,
      };
    },
  },
  methods: {
    sendEmail() {
      this.sending = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/emails", this.form)
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.sending = false;
          this.showReplyBox = false;
        });
    },
    fetchEmail() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/emails/change/" +
            this.$route.params.id
        )
        .then(({ data }) => {
          this.email = data;
        });
    },

    markDealtWith() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/emails/" +
            this.$route.params.id +
            "/dealt-with",
          { dealt_with: true }
        )
        .then(({ data }) => {
          this.$router.push("/emails/change");
        });
    },
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.fetchEmail();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
  },
  components: {
    Loading,
  },
};
</script>

<style></style>
